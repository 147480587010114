/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-shadow */
import { FunctionComponent, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../app/rootReducer";
import { sendDepartmentListRequest, sendGetAccountInfoRequest, clearAccountError, sendGetAccountListsRequest } from "../accountSlice";
import { logOut } from "../../auth/authSlice";
import RequireSeityImage from "../../../_core/utils/graphicUtils/localGraphic";

import strings from "../../../_core/strings/strings";
import "./styles.scss";
import SeityAvatar from "../../../_core/components/SeityAvatar";
import { getFSGroupList } from "../../familySharing/familySharingSlice";

export const Settings: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountInfo, departmentResponse } = useSelector((state: RootState) => {
    return state.account;
  });

  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  const { fsGroupList } = useSelector((state: RootState) => {
    return state.familySharingSlice;
  });

  const sections = [
    {
      title: strings.profile,
      imageName: "profile",
      onClick: () => history.push("/profile")
    },
    {
      title: strings.authenticationHeader,
      imageName: "key",
      onClick: () => history.push("/authentication"),
      id: "authentication"
    },
    // canCreateFamilySharing: should be over 18 age, otherwise can't create familygroup
    userAppSettings.hasFamilySharingFullAccess
      ? {
          title: strings.familySharing,
          imageName: "familySharing",
          onClick: () => history.push("/familyGroups", { isOrganizer: true })
        }
      : null,
    fsGroupList.filter((g) => !g.isOrganizer).length > 0
      ? {
          title: strings.familyProfile,
          imageName: "familyProfile",
          onClick: () => history.push("/familyGroups")
        }
      : null,
    (userAppSettings.coreValueDirectoryEnabled && userAppSettings.organizationCVDirectoryEnabled)
    ?   {
          title: strings.permissions,
          imageName: "permissions",
          onClick: () => history.push("/permissions")
        }
     : null,
    {
      title: strings.securityAndProtection,
      imageName: "privacy",
      onClick: () => history.push("/securityAndProtection")
    },
    userAppSettings.translationEnabled
      ? {
          title: strings.languagePreferences,
          imageName: "language",
          onClick: () => history.push("/language")
        }
      : null,
    {
      title: strings.support,
      imageName: "support",
      onClick: () => history.push("/support"),
      id: "logout"
    },
    {
      title: strings.logout,
      imageName: "logout",
      onClick: () => logOutClicked(),
      id: "logout"
    }
  ];

  useEffect(() => {
    clearAccountError();
    dispatch(sendGetAccountInfoRequest());
    dispatch(sendGetAccountListsRequest(true, true, true, true, true, true));
    dispatch(getFSGroupList());
  }, []);

  useEffect(() => {
    if (!departmentResponse) {
      dispatch(sendDepartmentListRequest());
    }
  }, [departmentResponse, dispatch]);

  const logOutClicked = () => {
    dispatch(logOut());
    history.push("/login");
  };

  if (accountInfo) {
    return (
      <div className="settings-container">
        <div className="settings-header">
          <SeityAvatar size={60} />
          <div>
            <h1>
              {accountInfo?.firstName} {accountInfo?.lastName}
            </h1>
            <p className="email">{accountInfo?.eMailAddress}</p>
          </div>
        </div>
        <div className="section-list">
          {sections.map((section, index) => {
            const isDisabled = !userAppSettings.settingsEnabled && section?.title !== strings.logout;
            return section ? (
              <div className={`section-list-item ${isDisabled ? "disabled" : ""}`} key={index} onClick={section.onClick} id={section?.id}>
                <img src={RequireSeityImage(section.imageName).default} />
                <h2>{section.title}</h2>
                <img className="caret" src={require("../../../assets/graphics/caret-black.png").default} />
              </div>
            ) : null;
          })}
        </div>
      </div>
    );
  }
  return <></>;
};
