import { FunctionComponent, ReactElement, useState } from "react";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import SeityButton from "../../../_core/components/SeityButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { postFSGroup } from "../familySharingSlice";

export type FSFamilyNameProps = {
  onClose: () => void;
  onContinue: () => void;
};

const FSFamilyName: FunctionComponent<FSFamilyNameProps> = ({
  onClose,
  onContinue
}): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountInfo } = useSelector((state: RootState) => {
    return state.account;
  });

  const { isLoading } = useSelector((state: RootState) => {
    return state.familySharingSlice;
  });

  const [name, setName] = useState("");

  return (
    <div className="fsFamilyName">
      {isLoading && <SeityLoader />}
      <button
        className="close-button"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <img src={require("../../../assets/graphics/journals/close.png").default} alt="close button" />
      </button>
      <img src={require("../../../assets/graphics/familysharing/icon-familysharing.png").default} alt="family sharing" width={72} />
      <h1>{CoreStrings.enterFamilyName}</h1>
      <input placeholder={CoreStrings.familyName} value={name} onChange={(e) => {
        setName(e.target.value);
      }} />
      
      <SeityButton className="fsWelcome-continue" label={CoreStrings.continue} onClick={() => {
        dispatch(postFSGroup(name, (groupId) => {
          history.push({
            pathname: `familyGroups/${groupId}`,
            state: {
              showInvite: true,
              isOrganizer: true
            }
          });
        }));
      }} disabled={name.length < 1} />
    </div>
  );
};

export default FSFamilyName;
