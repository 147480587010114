import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import { toastSuccess } from "../../../app/utils";
import FSEditNameModal from "./components/FSEditNameModal";
import { useHistory } from "react-router-dom";
import FSGroupAavatar from "./components/FSGroupAavatar";
import FSProfileCard from "../FSProfileCard";
import { FSGroupDetail, FSInviteStatus } from "../types";
import FSShortMenu from "./components/FSShortMenu";
import SeityModal from "../../../_core/components/SeityModal";
import { FSInviteForm } from "../FSInviteForm";
import FSMemberSidebar from "../FSMemberSidebar";
import { useDispatch, useSelector } from "react-redux";
import { sendGetSeityCoreValues } from "../../grow/learn/guideSlice";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { deleteFamilyGroup, deleteFamilyMember, deleteFamilyMemberInvite, postFamilyMemberInvite, putFSGroup } from "../familySharingSlice";
import { useFSMemberList } from "../hooks";
import { FSEditInviteForm } from "../FSEditInviteForm";
import FSWelcome from "../FSWelcome";

type FSGroupDetaiPrimaryProps = {
  groupData: FSGroupDetail;
  showInvite: boolean;
  groupID: number;
  fetchGroupDetail: () => void;
};

const FSGroupDetailPrimary: FunctionComponent<FSGroupDetaiPrimaryProps> = ({ groupData, showInvite, groupID, fetchGroupDetail }): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading: memberListLoading, memberList, fetchMemberList } = useFSMemberList(groupID);
  const { isLoading, coreValues } = useSelector((state: RootState) => state.guide);
  const { isLoading: fsLoading } = useSelector((state: RootState) => state.familySharingSlice);

  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [selMemberIdx, setSelMemberIdx] = useState(-1);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showDeleteInviteModal, setShowDeleteInviteModal] = useState(false);
  const [showInviteMember, setShowInviteMember] = useState(showInvite);
  const [showEditInviteMember, setShowEditInviteMember] = useState(false);
  const [showMemberProfile, setShowMemberProfile] = useState(false);
  const [showOrganizerProfile, setShowOrganizerProfile] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [changedProfilePicture, setChangedProfilePicture] = useState<string | null>(null);

  const handleInviteeShortMenuClick = (idx: number) => {
    if (idx === 2) { // on click remove invite
      setShowDeleteInviteModal(true);
    } else if (idx === 1) { // edit invite
      setShowEditInviteMember(true);
    } else if (idx === 0) { // resend invite
      const memberData = memberList[selMemberIdx];
      dispatch(postFamilyMemberInvite({
        groupId: groupID,
        firstName: memberData.firstName,
        lastName: memberData.lastName,
        relationship: memberData.relationship,
        dob: memberData.dob,
        email: memberData.email
      }, (success) => {
        if (success) {
          toastSuccess(`${CoreStrings.fsiToastSuccess} ${memberData.email}`);
        }
        setSelMemberIdx(-1);
      }));
    } else {
      setSelMemberIdx(-1);
    }
  };

  const handleMemberShortMenuClick = (idx: number) => {
    if (idx === 1) { // on click remove member
      setShowRemoveMemberModal(true);
    } else {
      setShowMemberProfile(true);
    }
  };

  useEffect(() => {
    if (showInviteMember || showEditInviteMember || showMemberProfile) {
      if (typeof window !== "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [selMemberIdx, showInviteMember, showEditInviteMember, showMemberProfile]);

  useEffect(() => {
    if (coreValues === null || coreValues?.length < 1) {
      dispatch(sendGetSeityCoreValues());
    }
  }, []);

  const handleRemoveModal = (actionIdx: number) => {
    setShowMemberProfile(false);
    setShowRemoveMemberModal(false);
    if (actionIdx === 0) {
      const memberData = memberList[selMemberIdx];
      // call remove member api
      dispatch(deleteFamilyMember(groupID, memberData.familySharingMemberID, () => {
        setSelMemberIdx(-1);
        fetchMemberList();
      }));
    }
  };

  const handleDeleteGroupModal = (actionIdx: number) => {
    setShowDeleteGroupModal(false);
    if (actionIdx === 0) {
      dispatch(deleteFamilyGroup(groupID, () => {
        history.push("/familyGroups", { isOrganizer: true })
      }));
    }
  };

  const handleDeleteInviteModal = (actionIdx: number) => {
    setShowDeleteInviteModal(false);
    if (actionIdx === 0) {
      const memberData = memberList[selMemberIdx];
      dispatch(deleteFamilyMemberInvite(memberData.familySharingMemberID, memberData.familySharingID, () => {
        setSelMemberIdx(-1);
        fetchMemberList();
      }));
    }
  };

  return (
    <div className="fsGroupsDetail">
      {(isLoading || fsLoading || memberListLoading) && <SeityLoader />}
      <div className="fsGroupsDetail-header">
        <FSGroupAavatar imgStr={changedProfilePicture ?? groupData.profilePicture} onUpdate={(imgData) => {
          setChangedProfilePicture(imgData);
          dispatch(putFSGroup(groupID, groupData.name, imgData));
        }} />
        <div className="fsGroupsDetail-header__name">
          <h1>{groupData.name}</h1>
          <button onClick={() => {
            setShowEditNameModal(true);
          }}>
            <img src={require("../../../assets/icons/pen-green.svg").default} />
          </button>
        </div>
        <button className="fsGroupsDetail-header__help" onClick={() => {
          setShowWelcome(true);
        }}>
          <img src={require("../../../assets/icons/familysharing-help.svg").default} />
        </button>
      </div>
      <div className="fsGroupsDetail-list">
        <h5>{CoreStrings.organizer}</h5>
        <div className="fsGroupsDetail-list__item">
          <FSProfileCard firstName={groupData.organizer.firstName} lastName={groupData.organizer.lastName} coreValues={groupData.organizer.coreValues} profileImg={groupData.organizer.profilePicture} onClickProfile={() => setShowOrganizerProfile(true)} />
        </div>
        <h5>{CoreStrings.members}</h5>
        {!isLoading && memberList?.length === 0 && (<h6>{CoreStrings.clickButtonToInviteMembers}</h6>)}
        {memberList && memberList.map((m, index) => {
          return (
            <div className="fsGroupsDetail-list__item" key={index}>
              <FSProfileCard firstName={m.firstName} lastName={m.lastName} coreValues={m.coreValues} profileImg={m.profilePicture} inviteStatus={m.familySharingStatusID} onClickProfile={() => { setSelMemberIdx(index); setShowMemberProfile(true); }} />
              <button onClick={() => {
                if (selMemberIdx === index) {
                  setSelMemberIdx(-1);
                } else {
                  setSelMemberIdx(index);
                }
              }}>
                <img src={require("../../../assets/icons/icon-3dots.svg").default} width={4} />
              </button>
              {index === selMemberIdx && (
                <FSShortMenu type={m.familySharingStatusID === FSInviteStatus.Accept ? "member" : "invite"} onClickMenu={(idx) => {
                  if (m.familySharingStatusID === FSInviteStatus.Accept)
                    handleMemberShortMenuClick(idx);
                  else
                    handleInviteeShortMenuClick(idx);
                }} />
              )}
            </div>
          );
        })}
      </div>
      <button className="fsGroupsDetail-delete" onClick={() => { setShowDeleteGroupModal(true); }}>
        <img src={require("../../../assets/icons/icon-recycle.svg").default} width={16} />
        <span>{CoreStrings.deleteFamilyGroup}</span>
      </button>
      <button className="fsGroups-add" onClick={() => {
        setShowInviteMember(true);
      }}>
        <img src={require("../../../assets/graphics/familysharing/icon-fs-add.png").default} />
      </button>
      {showEditNameModal && <FSEditNameModal name={groupData.name} onClose={(groupName) => {
        setShowEditNameModal(false);
        if (groupName) {
          dispatch(putFSGroup(groupID, groupName, null, () => {
            fetchGroupDetail();
          }));
        }
      }} />}
      {showRemoveMemberModal && (
        <SeityModal
          wrapperClassName="fsRemoveMemberModal"
          title={CoreStrings.areYouSure}
          text={memberList[selMemberIdx].willLoseSeityAccess ? CoreStrings.removeMemberAndAccess : CoreStrings.removeMemberDesc}
          showCloseBtn={true}
          isOpen={true}
          onClose={() => {
            setShowRemoveMemberModal(false);
          }}
          actionButtons={[
            { type: "submit", label: CoreStrings.remove },
            { type: "cancel", label: CoreStrings.cancelButtonLabel }
          ]}
          onClickActions={(idx) => {
            handleRemoveModal(idx);
          }}
        />
      )}
      {showDeleteGroupModal && <SeityModal
        wrapperClassName="fsRemoveMemberModal" title={`${CoreStrings.delete} ${groupData.name}`}
        text={CoreStrings.fsDeleteGroupDesc} showCloseBtn={true} isOpen={true} onClose={() => {
          setShowDeleteGroupModal(false);
        }}
        actionButtons={[
          { type: "submit", label: CoreStrings.deleteFamilyGroup },
          { type: "cancel", label: CoreStrings.cancelButtonLabel }
        ]}
        onClickActions={(idx) => { handleDeleteGroupModal(idx); }}
      />}
      {showDeleteInviteModal && <SeityModal
        wrapperClassName="fsRemoveMemberModal" title={CoreStrings.areYouSure}
        text={CoreStrings.fsDeleteInviteDesc} showCloseBtn={true} isOpen={true} onClose={() => {
          setShowDeleteInviteModal(false);
        }}
        actionButtons={[
          { type: "submit", label: CoreStrings.deleteInvite },
          { type: "cancel", label: CoreStrings.cancelButtonLabel }
        ]}
        onClickActions={(idx) => { handleDeleteInviteModal(idx); }}
      />}
      {showInviteMember && (
        <FSInviteForm groupID={groupID}
          onClose={(sentInvite) => {
            setShowInviteMember(false)
            if (sentInvite) {
              fetchMemberList();
            }
          }}
        />
      )}
      {showEditInviteMember && (
        <FSEditInviteForm groupID={groupID}
          memberData={memberList[selMemberIdx]}
          onClose={(sentInvite) => {
            setShowEditInviteMember(false)
            setSelMemberIdx(-1);
            if (sentInvite) {
              fetchMemberList();
            }
          }}
        />
      )}
      {(showOrganizerProfile || showMemberProfile) && (
        <FSMemberSidebar
          memberData={showOrganizerProfile ? groupData?.organizer : memberList[selMemberIdx]}
          onClose={() => {
            setShowOrganizerProfile(false);
            setShowMemberProfile(false);
            setSelMemberIdx(-1);
          }}
          organizerID={groupData.organizer.accountID}
          removeMemberFunction={setShowRemoveMemberModal}
        />
      )}
      {showWelcome && <FSWelcome onClose={() => {
        setShowWelcome(false);
      }} onContinue={() => {
        setShowWelcome(false);
      }} />}
    </div>
  );
};

export default FSGroupDetailPrimary;
