import { FunctionComponent, ReactElement } from "react";
import "./styles.scss";
import FSMemberProfile from "../FSMemberProfile";
import { FSGroupAccount } from "../types";
import CoreStrings from "../../../_core/strings/strings";

type FSMemberSidebarProps = {
  memberData: FSGroupAccount;
  organizerID?: number;
  isOrganizer?: boolean;
  onClose: () => void;
  isDirectoryMode?: boolean;
  removeMemberFunction?: any;
};

const FSMemberSidebar: FunctionComponent<FSMemberSidebarProps> = ({
  memberData,
  isOrganizer = false,
  onClose,
  isDirectoryMode = false
}): ReactElement => {
  let title;
  if (isOrganizer) {
    title = CoreStrings.organizerProfile;
  } else if (isDirectoryMode) {
    title = CoreStrings.coreCard;
  } else {
    title = CoreStrings.memberProfile;
  }
  return (
    <div className="fs-member-sidebar-container">
      <div className="header">
        <p>{title}</p>
        <button onClick={onClose}>
          <img src={require("../../../assets/graphics/today/close.png").default} />
        </button>
      </div>
      <FSMemberProfile memberData={memberData} isDirectoryMode={isDirectoryMode} />
    </div>
  );
};

export default FSMemberSidebar;
