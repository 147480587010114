import { FunctionComponent, useMemo, useState } from "react";
import "./styles.scss";
import { ContactListItem } from "../ContactListItem";
import FSMemberSidebar from "../../familySharing/FSMemberSidebar";
import { FSGroupAccount } from "../../familySharing/types";
import { RootState } from "../../../app/rootReducer";
import { useSelector } from "react-redux";

type ContactListProps = {
  alphabetArray: Array<string>;
  groupedValues: Array<string>;
  searchValue: string;
  isPersonalListMode: boolean;
  selectedAccounts: Array<number>;
  setSelectedAccounts: (value: Array<number> | []) => void;
};

export const ContactList: FunctionComponent<ContactListProps> = ({
  alphabetArray,
  groupedValues,
  searchValue,
  isPersonalListMode,
  selectedAccounts,
  setSelectedAccounts
}) => {
  const [showMemberProfile, setShowMemberProfile] = useState(false);
  const [activeUser, setActiveUser] = useState<FSGroupAccount | null>(null);

  // Personal List Mode
  const { personalListAccounts } = useSelector((state: RootState) => state.personalListSlice);

  const personalListAccountIDs = useMemo(() => {
    if (personalListAccounts) {
      let tempArr: Array<number> = [];
      personalListAccounts.forEach((account) => {
        tempArr.push(account.accountID);
      });

      return tempArr;
    }
    return [];
  }, [personalListAccounts]);

  const toggleSelect = (id) => {
    if (selectedAccounts.includes(id)) {
      // Deselect
      const index = selectedAccounts.indexOf(id);
      let tempArr = [...selectedAccounts];
      tempArr.splice(index, 1);
      setSelectedAccounts(tempArr);
    } else {
      setSelectedAccounts([...selectedAccounts, id]);
    }
  };
  // Personal List Mode

  const characterArray = useMemo(() => {
    let tempArray = [...alphabetArray];
    Object.keys(groupedValues).map((char) => {
      if (!tempArray.includes(char)) {
        tempArray.push(char);
      }
    });
    return tempArray;
  }, [groupedValues]);

  return (
    <div className={"contact-list"}>
      {characterArray.map((char) => {
        return (
          !!groupedValues[char]?.length && (
            <div id={`${char}-contact-list`} key={`val-${char}`} className="contact-list-char-group">
              <h2>{char}</h2>
              {groupedValues[char].map((user) => {
                if (personalListAccountIDs.includes(user.accountID)) {
                  return;
                } else {
                  return (
                    <div
                      key={`child-${user.accountID}`}
                      className={selectedAccounts.includes(user.accountID) ? "selected-account" : "unselected-account"}
                      onClick={() => {
                        if (isPersonalListMode) {
                          toggleSelect(user.accountID);
                          return;
                        }
                        setShowMemberProfile(true);
                        setActiveUser({
                          accountID: user.accountID,
                          firstName: user.firstName,
                          lastName: user.lastName,
                          profilePicture: user.profilePicture,
                          dob: "",
                          email: user.emailAddress,
                          coreValues: user.coreValues,
                          occupation: user.occupation
                        });
                      }}
                    >
                      <ContactListItem
                        coreValues={user.coreValues}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        profilePicture={user.profilePicture}
                        searchValue={searchValue}
                      />
                    </div>
                  );
                }
              })}
            </div>
          )
        );
      })}
      {showMemberProfile && activeUser && (
        <FSMemberSidebar
          memberData={activeUser}
          onClose={() => {
            setShowMemberProfile(false);
            setActiveUser(null);
          }}
          isDirectoryMode={true}
        />
      )}
    </div>
  );
};
