import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import FSWelcome from "../FSWelcome";
import FSFamilyName from "../FSFamilyName";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import FSNewFGModal from "./FSNewFGModal";
import SeityLoader from "../../../_core/components/SeityLoader";
import { getFSGroupList, getFSRelationshipList, getFSStatuses, postFSDisclosureAccept } from "../familySharingSlice";
import { RootState } from "../../../app/rootReducer";
import { FSHtmlModal } from "../FSHtmlModal";
import { useFSHtmlContent } from "../hooks";

export type FSGroupsStateProps = {
  isOrganizer: boolean;
};

const FSGroups: FunctionComponent = (): ReactElement => {
  const location = useLocation<FSGroupsStateProps>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { disclosure, isLoading: isDisclosureLoading } = useFSHtmlContent();

  const isOrganzier = location?.state?.isOrganizer ?? false;

  const { hasFamilySharingFullAccess, familySharingDisclosureAccepted } = useSelector((state: RootState) => state.userAppSettings.userAppSettings);
  const { fsGroupList } = useSelector((state: RootState) => state.familySharingSlice);

  const [isLoading, setLoading] = useState(true);
  const [showWelcome, setShowWelcome] = useState(fsGroupList.length === 0);
  const [showFamilyName, setShowFamilyName] = useState(false);
  const [showNewFGModal, setShowNewFGModal] = useState(false);
  const [disclosureStatus, setDisclosureStatus] = useState({
    showModal: !familySharingDisclosureAccepted,
    checked: familySharingDisclosureAccepted
  });

  useEffect(() => {
    const runApis = async () => {
      // await is to prevent isLoading being set to false by FSStatues and FSRel api calls
      setLoading(true);
      await dispatch(getFSGroupList());
      setLoading(false);
      dispatch(getFSStatuses());
      dispatch(getFSRelationshipList());
    };
    runApis();
  }, []);

  const groupList = useMemo(() => {
    return fsGroupList.filter(g => g.isOrganizer === isOrganzier);
  }, [fsGroupList, isOrganzier]);

  return (
    <div className="fsGroups">
      <h1>{CoreStrings.familyGroups}</h1>
      <div className="fsGroups-list">
        {isOrganzier && groupList.length < 1 && <h5>{CoreStrings.noFamilyGroupsCopy}</h5>}
        {isLoading ? (
          <SeityLoader hideBackgroundContent={groupList.length === 0} />
        ) : (
          <>
            {groupList.map((g, index) => {
              const profileImg = g.profilePicture ? `data:image/png;base64,${g.profilePicture}` : require("../../../assets/graphics/familysharing/img-profile-default.png").default;
              return (
                <div className="fsGroups-list__item" key={index} onClick={() => {
                  history.push({
                    pathname: "/familyGroups/" + g.familySharingID,
                    state: { showInvite: false, isOrganizer: g.isOrganizer }
                  });
                }}>
                  <img className="fsGroups-list__item-img" src={profileImg} />
                  <span>{g.name}</span>
                  <img src={require("../../../assets/graphics/caret-black.png").default} width={8} />
                </div>
              );
            })}
          </>
        )}
      </div>
      {(showWelcome && !familySharingDisclosureAccepted && !isLoading) && <FSWelcome onClose={() => {
        setShowWelcome(false);
        history.push('/settings');
      }} onContinue={() => {
        setShowWelcome(false);
        hasFamilySharingFullAccess && setShowFamilyName(true);
      }} />}
      {showFamilyName && disclosureStatus.showModal && !isDisclosureLoading && (
        <FSHtmlModal
          content={disclosure}
          onClose={() => {
            setDisclosureStatus({ ...disclosureStatus, showModal: false });
          }}
          onContinue={() => {
            dispatch(postFSDisclosureAccept());
            setDisclosureStatus({ checked: true, showModal: false });
          }}
        />
      )}
      {showFamilyName && <FSFamilyName onClose={() => {
        setShowFamilyName(false);
        setShowWelcome(true);
      }} onContinue={() => {
        setShowFamilyName(false);
      }} />}
      {isOrganzier && hasFamilySharingFullAccess && (
        <button className="fsGroups-add" onClick={() => {
          setShowNewFGModal(true);
        }}>
          <img src={require("../../../assets/graphics/familysharing/icon-fs-add.png").default} />
        </button>
      )}
      {showNewFGModal && <FSNewFGModal onClose={(groupId) => {
        setShowNewFGModal(false);
        if (groupId) {
          dispatch(getFSGroupList());
        }
      }} />}
    </div>
  );
};

export default FSGroups;