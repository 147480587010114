import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getCVIncentiveRequest } from "../../cvIncentiveSlice";
import strings from "../../../../_core/strings/strings";
import CVIncentiveModal from "../CoreValuesIncentiveModal";

import { RootState } from "../../../../app/rootReducer";
import SeityLoader from "../../../../_core/components/SeityLoader";

import "./styles.scss";
import { isPlotlineInjectionCompleted } from "../../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../../api/plotlineTypes";

export type CoreValuesOutroModalProps = {};

type StateProps = {
  fromPlotline: boolean;
};

export const CoreValuesOutroModal: FunctionComponent<CoreValuesOutroModalProps> = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<StateProps>();
  const [isShowIncentive, setShowIncentive] = useState(false);
  const fromPlotline = location?.state?.fromPlotline ?? false;

  const { isLoading: loadingCVIncentive, data: cvIncentiveData } = useSelector((state: RootState) => state.coreValuesIncentive);

  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  const nextPressed = () => {
    if (cvIncentiveData && cvIncentiveData.isActive) {
      setShowIncentive(true);
    } else {
      onClose();
    }
  };

  const onClose = () => {
    // if (fromPlotline && !isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.AfterCVSorted)) {
    //   history.push("/plotline/" + PlotlineInjectionPointID.AfterCVSorted);
    //   return;
    // }
    history.push("/today");
  };

  useEffect(() => {
    dispatch(getCVIncentiveRequest());
  }, []);

  if (!loadingCVIncentive) {
    return (
      <div className="cv-outro w-100 h-100">
        <button
          className="close-button"
          onClick={(e) => {
            e.preventDefault();
            nextPressed();
          }}
        >
          <img src={require("../../../../assets/graphics/close.png").default} alt="close button" />
        </button>
        <p className="cv-outro-01 cv-outro-anim">{strings.cvOutro1}</p>
        <p className="cv-outro-02 cv-outro-anim">{strings.cvOutro2}</p>
        <p className="cv-outro-03 cv-outro-anim">{strings.cvOutro3}</p>
        <img
          className="cv-outro-03 cv-outro-anim"
          src={require("../../../../assets/web-icons/seity-logo-white.png").default}
          alt="close button"
        />
        <CVIncentiveModal isVisible={isShowIncentive} setVisible={setShowIncentive} onCloseParent={onClose} data={cvIncentiveData} />
      </div>
    );
  }

  return <SeityLoader showBackgroundMask />;
};

export default CoreValuesOutroModal;
