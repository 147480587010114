import { useState, useEffect } from "react";
import strings from "../../strings/strings";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/rootReducer";
import { AssessmentLevel } from "../../../api/myStory/types/myStoryTypes";

const useHeaderMenuData = () => {
  const [headerMenuData, setHeaderMenuData] = useState<Array<any>>([]);
  const { userAppSettings } = useSelector((state: RootState) => {
    return state.userAppSettings;
  });

  useEffect(() => {
    const isWellbeingOnly = userAppSettings.isWellbeingOnly;
    // Resolve locale bug when refresh the browser: https://dev.azure.com/SeityHealthVision/Seity%20Health%20Essence/_workitems/edit/2971/
    setHeaderMenuData([
      {
        linkUrl: "/today",
        title: strings.today,
        enabled: true,
        hidden: isWellbeingOnly
      },
      {
        linkUrl: isWellbeingOnly ? "/" : "/wellbeing",
        title: strings.wellBeing,
        enabled: isWellbeingOnly || userAppSettings.wellbeingScreenEnabled,
        hidden: !userAppSettings.wellbeingScreenEnabled || isWellbeingOnly
      },
      {
        linkUrl: "/coreValues",
        title: strings.coreValues,
        enabled: true,
        hidden: isWellbeingOnly,
        relatedPaths: ["/coreValues/downloads"]
      },
      {
        linkUrl: "/grow",
        title: strings.grow,
        enabled: userAppSettings.growEnabled,
        hidden: isWellbeingOnly,
        submenu: [
          {
            linkUrl: "/wholeHealth",
            title: strings.wholeHealth,
            icon: "whole-health",
            hidden: userAppSettings.assessmentLevelID !== AssessmentLevel.FullAssessment
          },
          {
            linkUrl: "/journals",
            title: strings.journals,
            icon: "journal",
            hidden: !userAppSettings.journalsEnabled
          },
          {
            linkUrl: "/intentions",
            title: strings.intentions,
            icon: "intentions",
            hidden: !userAppSettings.intentionsEnabled
          },
          {
            linkUrl: "/mySyncs",
            title: strings.Syncs,
            icon: "syncs",
            hidden: !userAppSettings.syncsEnabled
          },
          {
            linkUrl: "/myStories",
            title: strings.Stories,
            icon: "stories",
            hidden: !userAppSettings.storiesEnabled
          },
          {
            linkUrl: "/learn",
            title: strings.learnSeity,
            icon: "learn",
            hidden: false
          }
        ]
      }
    ]);
  }, [strings.getLanguage(), userAppSettings]);

  return headerMenuData;
};

export default useHeaderMenuData;
