import { FunctionComponent, ReactElement, useCallback, useEffect, useState } from "react";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import { toastSuccess } from "../../../app/utils";
import FSEditNameModal from "../../familySharing/FSGroupDetail/components/FSEditNameModal";
import { useHistory, useParams } from "react-router-dom";
import FSGroupAavatar from "../../familySharing/FSGroupDetail/components/FSGroupAavatar";
import FSProfileCard from "../../familySharing/FSProfileCard";
import { PersonalGroupDetails } from "../types";
import FSShortMenu from "../../familySharing/FSGroupDetail/components/FSShortMenu";
import SeityModal from "../../../_core/components/SeityModal";
import { useDispatch, useSelector } from "react-redux";
import { sendGetSeityCoreValues } from "../../grow/learn/guideSlice";
import { RootState } from "../../../app/rootReducer";
import SeityLoader from "../../../_core/components/SeityLoader";
import { putFSGroup } from "../../familySharing/familySharingSlice";
import {
  deletePersonalList,
  deletePersonalListAccount,
  deletePersonalListImage,
  getPersonalList,
  getPersonalListAccounts,
  putPersonalListImageChange,
  putPersonalListNameChange
} from "../slice";
import PLMemberSidebar from "./PLMemberSidebar";

type PLDetailStateProps = {
  listID: string;
};

const PersonalListDetail: FunctionComponent = (): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isLoading, coreValues } = useSelector((state: RootState) => state.guide);
  const { isLoading: plLoading, personalListAccounts, personalList } = useSelector((state: RootState) => state.personalListSlice);

  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [selMemberIdx, setSelMemberIdx] = useState(-1);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showMemberProfile, setShowMemberProfile] = useState(false);

  const params = useParams<PLDetailStateProps>();
  const listID: number = parseInt(params.listID);
  const listData: PersonalGroupDetails = personalList.filter((list) => list.personalListID === listID)[0];

  const handleMemberShortMenuClick = (idx: number) => {
    if (idx === 1) {
      // on click remove member
      setShowRemoveMemberModal(true);
    } else {
      setShowMemberProfile(true);
    }
  };

  useEffect(() => {
    if (showMemberProfile) {
      if (typeof window !== "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "unset";
    }
  }, [selMemberIdx, showMemberProfile]);

  useEffect(() => {
    if (coreValues === null || coreValues?.length < 1) {
      dispatch(sendGetSeityCoreValues());
    }
    if (!listData) {
      dispatch(getPersonalList());
    }
    dispatch(getPersonalListAccounts(listID));
  }, []);

  const handleRemoveModal = (actionIdx: number) => {
    setShowRemoveMemberModal(false);
    if (actionIdx === 0) {
      const memberData = personalListAccounts[selMemberIdx];
      dispatch(
        deletePersonalListAccount(listID, memberData.personalListAccountID, () => {
          setSelMemberIdx(-1);
          dispatch(getPersonalListAccounts(listID));
        })
      );
    }
  };

  const handleDeleteListModal = (actionIdx: number) => {
    setShowDeleteGroupModal(false);
    if (actionIdx === 0) {
      dispatch(
        deletePersonalList(listID, () => {
          history.push("/cvdList");
        })
      );
    }
  };

  return (
    <div className="fsGroupsDetail">
      {isLoading || plLoading || !listData ? (
        <SeityLoader />
      ) : (
        <>
          <div className="fsGroupsDetail-header">
            <FSGroupAavatar
              imgStr={listData.image}
              onUpdate={(imgData) => {
                dispatch(
                  putPersonalListImageChange(imgData, listID, () => {
                    dispatch(getPersonalList());
                  })
                );
              }}
              isPersonalList={true}
              onRemove={() => {
                dispatch(
                  deletePersonalListImage(listID, () => {
                    dispatch(getPersonalList());
                  })
                );
              }}
            />
            <div className="fsGroupsDetail-header__name">
              <h1>{listData.personalListName}</h1>
              <button
                onClick={() => {
                  setShowEditNameModal(true);
                }}
              >
                <img src={require("../../../assets/icons/pen-green.svg").default} />
              </button>
            </div>
          </div>
          <div className="fsGroupsDetail-list">
            <h5>{CoreStrings.members}</h5>
            {!isLoading && personalListAccounts?.length === 0 && <h6>{CoreStrings.clickButtonToInviteMembers}</h6>}
            {personalListAccounts &&
              personalListAccounts.map((m, index) => {
                return (
                  <div className="fsGroupsDetail-list__item" key={index}>
                    <FSProfileCard
                      firstName={m.firstName}
                      lastName={m.lastName}
                      coreValues={m.coreValues}
                      profileImg={m.profilePicture ?? ""}
                      onClickProfile={() => {
                        setSelMemberIdx(index);
                        setShowMemberProfile(true);
                      }}
                    />
                    <button
                      onClick={() => {
                        if (selMemberIdx === index) {
                          setSelMemberIdx(-1);
                        } else {
                          setSelMemberIdx(index);
                        }
                      }}
                    >
                      <img src={require("../../../assets/icons/icon-3dots.svg").default} width={4} />
                    </button>
                    {index === selMemberIdx && (
                      <FSShortMenu
                        type={"personal"}
                        onClickMenu={(idx) => {
                          handleMemberShortMenuClick(idx);
                        }}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <button
            className="fsGroupsDetail-delete"
            onClick={() => {
              setShowDeleteGroupModal(true);
            }}
          >
            <img src={require("../../../assets/icons/icon-recycle.svg").default} width={16} />
            <span>{CoreStrings.deleteList}</span>
          </button>
          <button
            className="fsGroups-add"
            onClick={() => {
              history.push({
                pathname: "/directory",
                state: { isPersonalListMode: true, personalListID: listID }
              });
            }}
          >
            <img src={require("../../../assets/graphics/familysharing/icon-fs-add.png").default} />
          </button>
          {showEditNameModal && (
            <FSEditNameModal
              name={listData.personalListName}
              onClose={(listName) => {
                setShowEditNameModal(false);
                if (listName) {
                  dispatch(putPersonalListNameChange(listName, listID));
                }
              }}
              isPersonalList={true}
            />
          )}
          {showRemoveMemberModal && (
            <SeityModal
              wrapperClassName="fsRemoveMemberModal"
              title={CoreStrings.areYouSure}
              text={CoreStrings.removeMemberFromPLDesc}
              showCloseBtn={true}
              isOpen={true}
              onClose={() => {
                setShowRemoveMemberModal(false);
              }}
              actionButtons={[
                { type: "submit", label: CoreStrings.removeMember },
                { type: "cancel", label: CoreStrings.cancelButtonLabel }
              ]}
              onClickActions={(idx) => {
                handleRemoveModal(idx);
              }}
            />
          )}
          {showDeleteGroupModal && (
            <SeityModal
              wrapperClassName="fsRemoveMemberModal"
              title={`${CoreStrings.delete} ${listData.personalListName}`}
              text={CoreStrings.plDeleteDesc}
              showCloseBtn={true}
              isOpen={true}
              onClose={() => {
                setShowDeleteGroupModal(false);
              }}
              actionButtons={[
                { type: "submit", label: CoreStrings.deleteList },
                { type: "cancel", label: CoreStrings.cancelButtonLabel }
              ]}
              onClickActions={(idx) => {
                handleDeleteListModal(idx);
              }}
            />
          )}
          {showMemberProfile && (
            <PLMemberSidebar
              memberData={personalListAccounts[selMemberIdx]}
              onClose={() => {
                setShowMemberProfile(false);
                setSelMemberIdx(-1);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PersonalListDetail;
